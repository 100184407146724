/******************* Custom styles BR -- START*********************************/
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-layouts/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-treegrid/styles/material.css';
@import '../node_modules/@syncfusion/ej2-gantt/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-filemanager/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-documenteditor/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-kanban/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-spreadsheet/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-schedule/styles/material.css';
@import '../node_modules/@syncfusion/ej2-angular-diagrams/styles/material.css';

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: unset !important;
}

.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  border-radius: 50% 50% 0 !important;
}

.mat-slider-thumb {
  transform: scale(0) !important;
}

.mat-input-element {
  padding: 4px !important;
}

.mat-slider-thumb-label-text {
  opacity: 1 !important;
}

.bg-AO {
  background-color: rgb(39, 72, 107) !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgb(0 0 0 / 10%) !important;
}

// .mat-form-field {
//   font-size: 14px !important;
// }
.br-header {
  position: absolute !important;
}

.br-logo {
  z-index: unset !important;
}

.mat-tab-body-content {
  overflow-x: hidden !important;
}

.mat-dialog-container {
  font-size: 13px !important;
}

.cdk-overlay-pane {
  max-width: 99vw !important;
  max-height: 99vh !important;
}

.breadcrumb {
  margin-bottom: 0 !important;
}

.br-sideleft {
  z-index: 600 !important;
}

.br-header-left .input-group {
  // border-left: 1px solid #ced4da;
  // width: 500px;
  // margin-left: 300px;
}

.br-pagebody {
  // position: relative;
  // z-index: -1;
}

.br-menu-link.active {
  background-image: unset !important;
  background-repeat: unset !important;
  color: unset !important;
}

.nav-link-profile .square-10 {
  right: 15px !important;
}

.br-mainpanel {
  margin-top: 0 !important;
}

.br-header {
  z-index: unset !important;
}

.mat-paginator-container {
  border-top: 1px solid #e0e0e0;
}

.fl-table {
  table-layout: fixed;
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 97%;
  white-space: nowrap;
  background-color: white;
  font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont";
  margin-left: 20px;
}

.fl-table td,
.fl-table th {
  // text-align: center;
  padding: 5px;
}

.fl-table td {
  border-bottom: 1px solid #e0e0e0;
  font-size: 12px;
}

.overflowTxt {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fl-table thead th {
  color: rgba(0, 0, 0, 0.54);
  // background: #eee;
  font-weight: normal;
}

.fl-table tbody {
  // height:250px !important;
  overflow: auto !important;
}

.fl-table tr:hover {
  background: #eee;
}

[matSuffix] {
  font-size: 16px;
}

.fl-table td:last-child,
.fl-table th:last-child {
  text-align: right;
}

.e-kanban .e-kanban-table.e-content-table .e-card.e-selection {
  background-color: #fff !important;
  box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

/******************** Custom styles BR -- END***********************************/
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-snack-bar-container-success {
  padding: 30px !important;
  background-color: rgb(34, 126, 34);
  color: white !important;
}

.mat-snack-bar-container-success:before {
  content: "\e607";
  float: left;
  margin: 0 10px 0 0;
  display: block;
  font-size: 24px;
}

.mat-snack-bar-container-info {
  padding: 30px !important;
  background-color: #025c77;
  color: white !important;
  // color: #333;
  // background-color: #fff;
  // border: 1px solid #025c77;
}

.mat-snack-bar-container-info:before {
  content: "\e607";
  float: left;
  margin: 0 10px 0 0;
  display: block;
  font-size: 24px;
  // color: #025c77;
  // background: url("assets/images/AO.PNG") no-repeat;
  // width: 20px;
  // height: 20px;
  // float: left;
  // margin: 0 6px 0 0;
}

.nodata_display {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.mat-card-header-thub-green {
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(77, 85, 165, 1)), color-stop(100%, rgba(43, 185, 179, 1)));
  background: -webkit-linear-gradient(120deg, rgb(43, 185, 179) 0%, rgba(77, 85, 165, 1) 100%);
  background: -o-linear-gradient(120deg, rgba(43, 185, 179, 1) 0%, rgba(77, 85, 165, 1) 100%);
  background: linear-gradient(330deg, rgba(43, 185, 179, 1) 0%, rgba(77, 85, 165, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5834b2', endColorstr='#16b3aa', GradientType=0);

  color: white;
  text-align: center;
}

.mat-card-header-thub {
  background: -webkit-gradient(linear, left top, right bottom, color-stop(0%, rgba(77, 85, 165, 1)), color-stop(100%, rgba(43, 185, 179, 1)));
  background: -webkit-linear-gradient(120deg, rgb(43, 185, 179) 0%, rgba(77, 85, 165, 1) 100%);
  background: -o-linear-gradient(120deg, rgba(43, 185, 179, 1) 0%, rgba(77, 85, 165, 1) 100%);
  background: linear-gradient(330deg, rgba(43, 185, 179, 1) 0%, rgba(77, 85, 165, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5834b2', endColorstr='#16b3aa', GradientType=0);

  color: white;
}



.mat-snack-bar-container-warning {
  padding: 20px;
  background-color: #f89406;
  color: white;
}

.mat-snack-bar-container-danger {
  padding: 20px;
  background-color: red;
  color: white;
}

.btn-settings {
  font-size: 11px !important;
  margin-top: 15px;
  // border-radius: 0 !important;
  float: right;
  margin-right: 5px;
}

.btn-settings-font {
  font-size: 11px !important;
}

.mat-dialog-title {
  padding: 5px;
  font-size: 16px;
  // margin: -25px -24px 0px !important;
  display: block;
  background-color: #007dbb;
  color: #fff;
  border: 2px rgb(68, 66, 66);
  height: 40px;
  margin-bottom: 0 !important;
}

.mat-tab-body-wrapper {
  padding: 20px;
}

.mat-dialog-content,
.mat-dialog-container {
  overflow: hidden !important;
  max-height: unset !important;
  // height: 88% !important;
}

.mat-dialog-content {
  margin: 0 !important;
  // padding: 0;
  // height: 88% !important;
}

.mat-dialog-actions {
  margin-bottom: unset !important;
}

.btn_close {
  cursor: pointer !important;
  /* position: absolute !important;
 top: 4px;
  left: 5px */
}

.table-striped {

  background-color: #f7ffdd;
}

.mat-dialog-container {
  position: relative;
  border-radius: 0px !important;
  padding: unset !important;
}

.card-header {
  background-color: #f7ffdd;
  color: #424b54;
}

.table-hover tr:hover {
  background-color: #f7ffdd !important;
}


.table-wrapper {
  margin: 10px 70px 70px;
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.2);
}

.grid-content {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: calc(100vh - 400px);
  // margin-top: 15px;;
  display: inline-block;
}
.grid-content-noStatus {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: calc(100vh - 200px);
  // margin-top: 15px;;
  display: inline-block;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(56, 55, 55, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(71, 70, 70, 0.3);
  background-color: #555;
}

// .fl-table {
//   border-radius: 5px;
//   font-size: 12px;
//   font-weight: normal;
//   border: none;
//   border-collapse: collapse;
//   width: 100%;
//   white-space: pre-wrap;
//   background-color: white;
// }

// .fl-table td,
// .fl-table th {
//   text-align: center;
//   padding: 8px;
// }

// .fl-table td {
//   border-right: 1px solid #f8f8f8;
//   font-size: 12px;
// }
// .mat-tab-body-wrapper {
//   padding: 15px;
// }
// .fl-table thead th {
//   color: #ffffff;
//   background: #4FC3A1;
// }

// .fl-table thead th:nth-child(odd) {
//   color: #ffffff;
//   background: #324960;
// }

// .fl-table tr:nth-child(even) {
//   background: #F8F8F8;
// }
/* Responsive */

@media (max-width: 767px) {

  .grid-content {
    overflow: auto;
    width: 100%;
    height: 100%;
    margin-top: 15px;
    ;
    display: inline-block;
  }

  .fl-table {
    display: block;
    width: 100%;
  }

  .table-wrapper:before {
    content: "Scroll horizontally >";
    display: block;
    text-align: right;
    font-size: 11px;
    color: white;
    padding: 0 0 10px;
  }

  .fl-table thead,
  .fl-table tbody,
  .fl-table thead th {
    display: block;
  }

  .fl-table thead th:last-child {
    border-bottom: none;
  }

  .fl-table thead {
    float: right;
  }

  .fl-table tbody {
    width: auto;
    position: relative;
    overflow-x: auto;
  }

  .fl-table .fl-table td,
  .fl-table th {
    padding: 10px .625em .625em .625em;
    /* height: 60px; */
    vertical-align: middle;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    /* width: 120px; */
    font-size: 13px;
    text-overflow: ellipsis;
  }

  .fl-table thead th {
    text-align: center;
    border-bottom: 1px solid #f7f7f9;
    height: 130px;
    position: static;

  }

  .fl-table tbody tr {
    display: table-cell;
  }

  .fl-table tbody tr:nth-child(odd) {
    background: none;
  }

  .fl-table tr:nth-child(even) {
    background: transparent;
  }

  .fl-table tr td:nth-child(odd) {
    background: #F8F8F8;
    border-right: 1px solid #E6E4E4;
  }

  .fl-table tr td:nth-child(even) {
    border-right: 1px solid #E6E4E4;
  }

  .fl-table tbody td {
    display: block;
    text-align: center;
    height: 130px;
  }

  .fl-table tbody td {
    display: block;
    text-align: center;
    height: 130px;
  }
}

.fl-table>thead>tr>th>grid-filter>span {
  display: block;
  cursor: pointer;

}

.fl-table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 500;
  background-color: #fff !important;
  border-bottom: 1px solid #ddd;
}

.fl-table>thead>tr>th>grid-filter>input {
  width: 70%;
}

.fl-table>thead>tr>th>span {
  display: block;
  cursor: pointer;
}

.fl-table>thead>tr>th>input {
  width: 70%;
}

.mat-raised-button {
  margin-left: 10px !important;
}

.mat-stroked-button {
  margin-left: 10px !important;
}

button {
  outline: none !important;
  border: 0px;
}

.mat-menu-item {
  line-height: 30px !important;
  height: 30px !important;

}

.opration {
  white-space: nowrap
}